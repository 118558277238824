import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Container, Nav, NavDropdown, Navbar, Button, Col } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import Logo from "../../images/logo(1).svg";
import BackgroundLogo from "../../images/bckgrnd.png";
import style from '../../styles/header.module.css'
import axios from "axios";
import { BASE_URL } from "../../config";
import Badge from '@mui/material/Badge';
import { useCart } from "react-use-cart";
import BlackCallIcon from '../../images/Icons/phone-black.png';
import LocIcon from "../../images/Icons/location.png";
import Locate from '../../images/Icons/location-white.png';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputBase from '@mui/material/InputBase';
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Menubar = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const languages = [
        { name: "English", code: "en" },
        { name: "Arabic", code: "ar", dir: "rtl" },
    ]


    const {
        isEmpty,
        totalUniqueItems,
        totalItems,
        items,
        updateItemQuantity,
        removeItem,
    } = useCart();
    // const navigate = useNavigate();
    const { pathname } = useLocation();
    // const { t, i18n } = useTranslation();
    // const currentLocale = Cookies.get("i18next") || "en";
    const currentLocale = "en";
    const [language, setLanguage] = useState(localStorage.getItem("language") ? localStorage.getItem("language") : currentLocale);
    const [langs, setlang] = useState('');
    const [socaial, setsocial] = useState('');
    const [setting, setsetting] = useState('');
    const [show, setShow] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const [headerData, setHeaderData] = useState([]);
    const handleChangeLocale = (e) => {
        const lang = e.target.value;
        setLanguage(lang);
        localStorage.setItem("language", lang);
        i18n.changeLanguage(lang);
    };




    const currentLangObj = languages.find((lang) => lang.code === currentLocale);

    useEffect(() => {
        document.title = t("app_title");
        const fetchsocialsetting = async () => {
            await axios.get(BASE_URL + '/api/social-settings').then(({ data }) => {
                setsocial(data.data);
            })
        }
        const fetchlanguage = async () => {
            await axios.get(BASE_URL + '/api/site-settings').then(({ data }) => {
                setlang(data.data.lan_ar);
                setsetting(data.data);
            })
        }
        const fetchHeaderData = async () => {
            await axios.get(BASE_URL + '/api/header-settings').then(({ data }) => {
                setHeaderData(data.data);
            })
        }
        fetchsocialsetting();
        fetchlanguage();
        fetchHeaderData();

    }, [pathname])


    function Logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("userdata");
        navigate('/')
    }
    const user = JSON.parse(localStorage.getItem("userdata"))
    const [profile, setprofile] = React.useState(10);
    const handleChange = (event) => {
        setprofile(event.target.value);
    };

    return (
        <>
            <div className={style.menuHeader} >
                <Navbar className={`mb-3 menuNavbar ${style.navbar}`} collapseOnSelect expand="lg" >
                    <div>
                        <a href="/">
                            <img src={BackgroundLogo} alt="" className={style.logo} />
                        </a>
                        <a href="/">
                        <img src={BASE_URL + '/storage/' + headerData.header_logo} alt="" className={style.imgLogo} />
                        </a>
                    </div>

                    
                    <div>
                        <Navbar.Offcanvas placement="end" id="responsive-navbar-nav" style={{ width: 250 }} >

                            <Nav className="me-auto">
                                <Link className={` ${style.navbarFont} navbar-links`} to="/">
                                    {t("menu.home")}
                                </Link>
                                
                                <NavDropdown
                                    className="text-muted navbar-links"
                                    title={<span className={style.navbarFont}>{t("menu.shopbycategory")}</span>}
                                    
                                >   
                                    
                                    <NavDropdown.Item className="text-muted navLinks" onClick={() => navigate("/hydraulic-equipment")}>
                                        {t("menu.hydraulicequipment")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="text-muted navLinks" onClick={() => navigate("/injector-equipment")}>
                                        {t("menu.injectorequipment")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="text-muted navLinks" onClick={() => navigate("/lighting-equipment")}>
                                        {t("menu.lightingequipment")}
                                    </NavDropdown.Item>
									<NavDropdown.Item className="text-muted navLinks" onClick={() => navigate("/lifting-equipment")}>
                                        {t("menu.liftingequipment")}
                                    </NavDropdown.Item>
									
                                    <NavDropdown.Item className="text-muted navLinks" onClick={() => navigate("/lubrication-equipment")}>
                                        {t("menu.lubricationequipment")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="text-muted navLinks" onClick={() => navigate("/paint-equipment")}>
                                        {t("menu.paintequipment")}
                                        </NavDropdown.Item>
                                    <NavDropdown.Item className="text-muted navLinks" onClick={() => navigate("/pressure-washer")}>
                                        {t("menu.pressurewasher")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="text-muted navLinks" onClick={() => navigate("/tools")}>
                                        {t("menu.tools")}
                                    </NavDropdown.Item>
                                     <NavDropdown.Item className="text-muted navLinks" onClick={() => navigate("/tyre-changer")}>
                                        {t("menu.tyrechanger")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="text-muted navLinks" onClick={() => navigate("/vacuum-equipment")}>
                                        {t("menu.vacuumequipment")}
                                    </NavDropdown.Item>
                            
                                    <NavDropdown.Item className="text-muted navLinks" onClick={() => navigate("/wheel-alignment")}>
                                        {t("menu.wheelalignment")}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className="text-muted navLinks" onClick={() => navigate("/wheel-balancer")}>
                                        {t("menu.wheelbalancer")}
                                    </NavDropdown.Item>


                                </NavDropdown>
                                <Link className={` ${style.navbarFont} navbar-links`} to="/blog">
                                    {t("menu.blog")}
                                </Link>

                                

                                <Link className={` ${style.navbarFont} navbar-links`} to="/contact-us">
                                    {t("contact")}
                                </Link>
                                {/*<Link className={` ${style.navbarFont} navbar-links`} to="/about-us">
                                    {t("menu.about")}
                                </Link> */}
            
                                <Link className={` ${style.navbarFont} navbar-links`} to="#">
                                    <Button className={style.contactButton}>{t("downloadcatalogue")}</Button>
                                </Link>
                            </Nav>

                        </Navbar.Offcanvas>
                    </div>
                    <div>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" className={style.mr20}  />
                    </div>
                </Navbar>
            </div>
            
        </>
    )
}

export default Menubar;